

import billService from './bills.service'
import eventsHandler from '@/services/common/eventsHandler.js'
import { deepClone, reject } from 'lodash'

class BillBarCalculator extends eventsHandler {

  constructor() {
    super()
    this._bill = undefined
    this._discountBar = undefined
    this._customerCard = undefined
    this._useCustomerCard = false
  }

  get total() {
    return this.calculateTotal('all')
  }

  get servicesTotal() {
    return this.calculateTotal('service')
  }

  get itemsTotal() {
    return this.calculateTotal('item')
  }

  get totalGhost() {
    return this.calculateTotal('ghost')
  }

  get subTotal() {
    return this.calculateSubTotal()
  }

  get discount() {
    return this.calculateDiscount()
  }

  get cardRemaining() {
    return !!this._customerCard ? this._customerCard.remaining : undefined
  }

  get toBePaid() {
    return this.calculateToBePaid()
  }

  get newCardRemaining() {
    return this.calculateNewCardRemaining()
  }

  get totalFormatted() {
    const total = this.total
    return !!total ? total + ' €' : 'Non specificato'
  }

  get subTotalFormatted() {
    const total = this.subTotal
    return !!total ? total + ' €' : 'Non specificato'
  }

  get bill() {
    return deepClone(this._bill)
  }

  get discountBar() {
    return this._discountBar
  }

  setBill(bill) {
    return new Promise((resolve, reject) => {
      if (!bill || !bill.id) {
        reject(new Error("bill is not specified"))
        return
      } else if (!bill.billItems) {
        billService.get(bill).then((newBill) => {
          this._bill = newBill
          this._discountBar = undefined
          resolve(newBill)
        })
      } else {
        this._bill = bill
        resolve(bill)
      }

      this._emitUpdated()
    })
  }

  setGeneralDiscountBar(discount) {
    return new Promise((resolve, reject) => {
      if (!discount) {
        reject(new Error("discount is not defined"))
        return
      } else {
        this._discountBar = discount
        resolve(discount)
      }

      this._emitUpdated()
    })
  }

  setCustomerCard(customerCard) {
    return new Promise((resolve, reject) => {
      if (customerCard == undefined || customerCard == null) {
        this._customerCard = undefined
      } else if (!customerCard || !customerCard.id) {
        reject(new Error("customer card is not defined"))
        return
      } else if (!customerCard) {

      } else {
        this._customerCard = customerCard
        resolve()
      }

      this._emitUpdated()
    })
  }

  useCard(use) {
    this._useCustomerCard = use
    this._emitUpdated()
  }

  calculateDiscountShot(billService, price) {
    const localPrice = (price != undefined && price != null) ? Number(price) : Number(billService.service.price)

    if (!!billService.editPriceType && !!billService.editPriceValue) {
      if (billService.editPriceType == 'number') {
        let percentageFromOtherDiscount = !!price ? (Number(price) * 100) / Number(billService.service.price) : 0
        return percentageFromOtherDiscount > 0 ? (Number(localPrice) - ((percentageFromOtherDiscount * Number(billService.editPriceValue)) / 100)).toFixed(2)
          : (Number(localPrice) - Number(billService.editPriceValue)).toFixed(2)
      }
      else if (billService.editPriceType == 'percentage') {
        return (Number(localPrice) - (Number(localPrice) * (billService.editPriceValue / 100)))
      }
    }
    else {
      return localPrice
    }
  }

  calculateDiscountShotItem(billItem, price) {
    const localPrice = (price != undefined && price != null) ? price : billItem.item.priceSelling
    if (!!billItem.editPriceType && !!billItem.editPriceValue) {
      if (billItem.editPriceType == 'number') {
        let percentageFromOtherDiscount = !!price ? (Number(price) * 100) / Number(billItem.item.priceSelling) : 0
        return percentageFromOtherDiscount > 0 ? (Number(localPrice) - ((percentageFromOtherDiscount * Number(billItem.editPriceValue)) / 100)).toFixed(2)
          : (Number(localPrice) - Number(billItem.editPriceValue)).toFixed(2)
      }
      else if (billItem.editPriceType == 'percentage') {
        return (Number(localPrice) - (Number(localPrice) * (billItem.editPriceValue / 100)))
      }
    }
    else {
      return localPrice
    }
  }

  calculateTotal(type) {
    let total = 0

    if (type == 'service' || type == 'all' && !!this._bill && !!this._bill.billServices && Array.isArray(this._bill.billServices)) {
      total += this._bill.billServices.reduce((accumulator, billService, index, array) => {
        if (!!billService.editPriceType && !!billService.editPriceValue && !billService.ghost) {
          const singleServiceDiscount = this.calculateDiscountShot(billService)
          const totalServicePrice = parseInt(billService.quantity) * parseFloat(singleServiceDiscount)
          return Math.round((accumulator + (Math.round(totalServicePrice * 100) / 100)) * 100) / 100
        } else if (!billService.ghost) {
          const totalServicePrice = parseInt(billService.quantity) * parseFloat(billService.service.price)
          return Math.round((accumulator + (Math.round(totalServicePrice * 100) / 100)) * 100) / 100
        } else {
          const realQuantity = parseInt(billService.quantity) - parseInt(billService.ghostQuantity)
          const totalServicePrice = parseInt(realQuantity) * parseFloat(billService.service.price)
          return Math.round((accumulator + (Math.round(totalServicePrice * 100) / 100)) * 100) / 100
        }
      }, 0)
    }

    if (type == 'item' || type == 'all' && !!this._bill && !!this._bill.billItems && Array.isArray(this._bill.billItems)) {
      total += this._bill.billItems.reduce((accumulator, billItem, index, array) => {
        if (!!billItem.editPriceType && !!billItem.editPriceValue && !billItem.ghost) {
          const singelItemDiscount = this.calculateDiscountShotItem(billItem)
          const totalItemPrice = parseInt(billItem.quantity) * parseFloat(singelItemDiscount)
          return Math.round((accumulator + (Math.round(totalItemPrice * 100) / 100)) * 100) / 100
        } else if (!billItem.ghost) {
          const totalItemPrice = parseInt(billItem.quantity) * parseFloat(billItem.item.priceSelling)
          return Math.round((accumulator + (Math.round(totalItemPrice * 100) / 100)) * 100) / 100
        } else {
          const realQuantity = parseInt(billItem.quantity) - parseInt(billItem.ghostQuantity)
          const totalItemPrice = parseInt(realQuantity) * parseFloat(billItem.item.priceSelling)
          return Math.round((accumulator + (Math.round(totalItemPrice * 100) / 100)) * 100) / 100
        }
      }, 0)
    }

    if (type == 'ghost' && !!this._bill) {
      total += this._bill.billServices.reduce((accumulator, billService, index, array) => {
        if (!!billService.editPriceType && !!billService.editPriceValue && billService.ghost) {
          const singleServiceDiscount = this.calculateDiscountShot(billService)
          const totalServicePrice = parseInt(billService.quantity) * parseFloat(singleServiceDiscount)
          return Math.round((accumulator + (Math.round(totalServicePrice * 100) / 100)) * 100) / 100
        } else if (billService.ghost) {
          const discount = !!billService.discount ? Number(billService.discount) : 0
          const totalServicePrice = (parseInt(billService.quantity) * parseFloat(billService.service.price)) - discount
          return Math.round((accumulator + (Math.round(totalServicePrice * 100) / 100)) * 100) / 100
        }
      }, 0)
    }

    return Number(Number(total).toFixed(2))
  }

  calculateDiscount() {
    if (!!this._discountBar && !!this._discountBar.type && !!this._discountBar.value) {
      if (this._discountBar.type == 'percentage') {
        return Number(Number(this.total).toFixed(2) * Number(Number(this._discountBar.value).toFixed(2) / 100).toFixed(2)).toFixed(2)
      } else if (this._discountBar.type == 'number') {
        return Number(this._discountBar.value).toFixed(2)
      }
    }
    if (!!this._customerCard && !!this._customerCard.serviceDiscounts && this._useCustomerCard) {
      return (this.total * (this._customerCard.serviceDiscounts / 100).toFixed(2)).toFixed(2)
    } else {
      return 0
    }
  }

  calculateEditedPrice(billService, price) {
    const localPrice = !!price ? price : billService.service.price
    if (!!billService.editPriceType && !!billService.editPriceValue) {
      if (billService.editPriceType == 'number') {
        let percentageFromOtherDiscount = !!price ? (Number(price) * 100) / Number(billService.service.price) : 0

        //billService.discount = percentageFromOtherDiscount > 0 ? (percentageFromOtherDiscount * Number(billService.editPriceValue)) / 100 : Number(billService.editPriceValue)
        //billService.discount = (Number(billService.discount) * Number(billService.quantity)).toFixed(2)

        return percentageFromOtherDiscount > 0 ? (localPrice - ((percentageFromOtherDiscount * Number(billService.editPriceValue)) / 100)).toFixed(2)
          : (Number(localPrice) - Number(billService.editPriceValue)).toFixed(2)
      }
      else if (billService.editPriceType == 'percentage') {
        //billService.discount = (Number(localPrice) * (billService.editPriceValue / 100)).toFixed(2)
        //billService.discount = (Number(billService.discount) * Number(billService.quantity)).toFixed(2)

        return (Number(localPrice) - (Number(localPrice) * (billService.editPriceValue / 100)))
      }
    }
  }

  calculateEditedItemPrice(billItem, price) {
    const localPrice = !!price ? price : billItem.item.priceSelling
    if (!!billItem.editPriceType && !!billItem.editPriceValue) {
      if (billItem.editPriceType == 'number') {
        let percentageFromOtherDiscount = !!price ? (Number(price) * 100) / Number(billItem.item.priceSelling) : 0

        //billItem.discount = percentageFromOtherDiscount > 0 ? (percentageFromOtherDiscount * Number(billItem.editPriceValue)) / 100 : Number(billItem.editPriceValue)
        //billItem.discount = (Number(billItem.discount) * Number(billItem.quantity)).toFixed(2)

        return percentageFromOtherDiscount > 0 ? (localPrice - ((percentageFromOtherDiscount * Number(billItem.editPriceValue)) / 100)).toFixed(2)
          : (Number(localPrice) - Number(billItem.editPriceValue)).toFixed(2)
      }
      else if (billItem.editPriceType == 'percentage') {
        //billItem.discount = (Number(localPrice) * (billItem.editPriceValue / 100)).toFixed(2)
        //billItem.discount = (Number(billItem.discount) * Number(billItem.quantity)).toFixed(2)

        return (Number(localPrice) - (Number(localPrice) * (billItem.editPriceValue / 100)))
      }
    }
  }

  calculateSubTotal() {
    if (!!this.discount) {
      return Math.max(0, Number((this.total - Number(Number(this.discount).toFixed(2))).toFixed(2)))
    } else {
      return this.total
    }
  }

  calculateToBePaid() {
    if (!!this.subTotal && this._useCustomerCard) {
      let toBePaid = (this.subTotal - this.cardRemaining).toFixed(2)
      return toBePaid < 0 ? 0 : toBePaid
    } else {
      return this.subTotal
    }
  }

  calculateNewCardRemaining() {
    const cardRemaining = this.cardRemaining

    if (!!cardRemaining) {
      if (this._useCustomerCard) {
        let newCustomerCardRemaining = (cardRemaining - this.subTotal).toFixed(2)
        return newCustomerCardRemaining > 0 ? newCustomerCardRemaining : 0
      } else {
        return cardRemaining
      }
    } else {
      return undefined
    }
  }

  _emitUpdated() {
    this._executeAsyncCallbacksOf('update')
  }
}

export default new BillBarCalculator()
import billService from './bills.service'
import eventsHandler from '@/services/common/eventsHandler.js'
import { deepClone, partial } from 'lodash'

class BillCalculator extends eventsHandler {

  constructor() {
    super()
    this._bill = undefined
    this._customerCard = undefined
    this._useCustomerCard = false
    this._usePromo = false
    this._promosCustomers = []
  }

  get total() {
    return this.calculateTotal('all')
  }

  get subTotal() {
    return this.calculateSubTotal()
  }

  get servicesTotal() {
    return this.calculateTotal('service')
  }

  get itemsTotal() {
    return this.calculateTotal('item')
  }

  get totalGhost() {
    return this.calculateTotal('ghost')
  }

  get discount() {
    return Number(Number(this.calculateDiscount()).toFixed(2))
  }

  get discountOnTotal() {
    return this.calculateDiscountOnTotal()
  }

  get cardRemaining() {
    return !!this._customerCard ? this._customerCard.remaining : undefined
  }

  get customerCardCreditGhost() {
    if (!!this._customerCard && !!this._customerCard.ghost) {
      return true
    } else {
      return false
    }
  }

  get promosCustomerUsed() {
    return this._promosCustomers.length > 0 && this._usePromo ? this._promosCustomers.find((promoCustomer) => !!promoCustomer.selected) : undefined
  }

  get promoUsed() {
    return !!this.promosCustomerUsed && this._usePromo ? this.promosCustomerUsed.promo : undefined
  }

  get toBePaid() {
    return this.calculateToBePaid()
  }

  get newCardRemaining() {
    return this.calculateNewCardRemaining()
  }

  get customerCard() {
    return this._useCustomerCard
  }

  get customerCardEntities() {
    let areServicesPresent = false
    let areItemsPresent = false

    if (!!this._bill) {
      areServicesPresent = !!this._bill.billServices ? this._bill.billServices.length > 0 : false
      areItemsPresent = !!this._bill.billItems ? this._bill.billItems.length > 0 : false
    }

    return this._useCustomerCard && !!areServicesPresent && !!areItemsPresent
  }

  get cardSubtract() {
    return Number((this.cardRemaining - this.newCardRemaining).toFixed(2))
  }

  get remainingPromo() {
    return this.calculateRemainingPromo()
  }

  get totalFormatted() {
    const total = this.total
    return !!total ? total + ' €' : 'Non specificato'
  }

  get subTotalFormatted() {
    const total = this.subTotal
    return !!total ? total + ' €' : 'Non specificato'
  }

  get bill() {
    return deepClone(this._bill)
  }

  setBill(bill) {
    return new Promise((resolve, reject) => {
      if (!bill || !bill.id) {
        reject(new Error("bill is not specified"))
        return
      } else if (!bill.billServices && !bill.billItems) {
        billService.get(bill).then((newBill) => {
          this._bill = newBill
          resolve(newBill)
        })
      } else {
        this._bill = bill
        resolve(bill)
      }

      this._emitUpdated()
    })
  }

  setCustomerCard(customerCard) {
    return new Promise((resolve, reject) => {
      if (customerCard == undefined || customerCard == null) {
        this._customerCard = undefined
      } else if (!customerCard || !customerCard.id) {
        reject(new Error("customer card is not defined"))
        return
      } else if (!customerCard) {

      } else {
        this._customerCard = customerCard
        resolve()
      }

      this._emitUpdated()
    })
  }

  setPromosCustomers(promosCustomers = []) {
    return new Promise((resolve, reject) => {
      if (promosCustomers == undefined || promosCustomers == null || (Array.isArray(promosCustomers) && promosCustomers.length == 0)) {
        this._promosCustomers = []
      } else {
        this._promosCustomers = promosCustomers.sort((a, b) => b.selected)
      }

      resolve()

      this._emitUpdated()
    })
  }

  useCard(use) {
    this._useCustomerCard = use
    this._emitUpdated()
  }

  usePromos(use) {
    this._usePromo = use
    this._emitUpdated()
  }

  calculateEditedPrice(billService, price) {
    const localPrice = (price != undefined && price != null) ? Number(price) : Number(billService.service.price)

    if (!!billService.editPriceType && !!billService.editPriceValue) {
      if (billService.editPriceType == 'number') {
        let percentageFromOtherDiscount = !!price ? (Number(price) * 100) / Number(billService.service.price) : 0
/*         return percentageFromOtherDiscount > 0 ? (Number(localPrice) - ((percentageFromOtherDiscount * Number(billService.editPriceValue)) / 100)).toFixed(2)
          :  */ return Number((Number(localPrice) - Number(billService.editPriceValue)).toFixed(2))
      }
      else if (billService.editPriceType == 'percentage') {
        return (Number(localPrice) - (Number(localPrice) * (billService.editPriceValue / 100)))
      }
    }
    else {
      return localPrice
    }
  }

  calculateDiscountShot(billService, price) {
    const localPrice = (price != undefined && price != null) ? Number(price) : Number(billService.service.price)

    if (!!billService.discountType && !!billService.discountValue) {
      if (billService.discountType == 'number') {
        return (Number(localPrice) - Number(billService.discountValue)).toFixed(2)
      }
      else if (billService.discountType == 'percentage') {
        return (Number(localPrice) - (Number(localPrice) * (billService.discountValue / 100)))
      }
    }
    else {
      return localPrice
    }
  }

  calculateEditedItemPrice(billItem, price) {
    const localPrice = (price != undefined && price != null) ? Number(price) : Number(billItem.item.priceSelling)
    if (!!billItem.editPriceType && !!billItem.editPriceValue) {
      if (billItem.editPriceType == 'number') {
        return (Number(localPrice) - Number(billItem.editPriceValue)).toFixed(2)
      }
      else if (billItem.editPriceType == 'percentage') {
        return (Number(localPrice) - (Number(localPrice) * (billItem.editPriceValue / 100)))
      }
    }
    else {
      return localPrice
    }
  }

  calculateDiscountShotItem(billItem, price) {
    const localPrice = (price != undefined && price != null) ? Number(price) : (billItem.item.priceSelling)

    if (!!billItem.discountType && !!billItem.discountValue) {
      if (billItem.discountType == 'number') {
        return (Number(localPrice) - Number(billItem.discountValue)).toFixed(2)
      }
      else if (billItem.discountType == 'percentage') {
        return (Number(localPrice) - (Number(localPrice) * (billItem.discountValue / 100)))
      }
    }
    else {
      return localPrice
    }
  }


  calculateTotal(type) {
    let total = 0

    if (type == 'service' || type == 'all' && !!this._bill && !!this._bill.billServices && Array.isArray(this._bill.billServices)) {
      total += this._bill.billServices.reduce((accumulator, billService, index, array) => {

        let servicePrice = this.calculateEditedPrice(billService)
        if (type == 'service')
          servicePrice = this.calculateDiscountShot(billService, servicePrice)
/*         servicePrice = !!billService.discountType && !!billService.discountValue ? 
          this.calculateDiscountShot(billService, servicePrice) : servicePrice */

/*         if (!!billService.editPriceType && !!billService.editPriceValue && !billService.ghost) {
          let singleServiceDiscount = this.calculateEditedPrice(billService, servicePrice)
          const totalServicePrice = parseInt(billService.quantity) * parseFloat(singleServiceDiscount)
          return Math.round((accumulator + (Math.round(totalServicePrice * 100) / 100)) * 100) / 100
        } else */ if (!billService.ghost) {
          const totalServicePrice = parseInt(billService.quantity) * parseFloat(servicePrice)
          return Math.round((accumulator + (Math.round(totalServicePrice * 100) / 100)) * 100) / 100
        } else {
          const realQuantity = parseInt(billService.quantity) - parseInt(billService.ghostQuantity)
          const totalServicePrice = parseInt(realQuantity) * parseFloat(servicePrice)
          return Math.round((accumulator + (Math.round(totalServicePrice * 100) / 100)) * 100) / 100
        }
      }, 0)
    }

    if (type == 'item' || type == 'all' && !!this._bill && !!this._bill.billItems && Array.isArray(this._bill.billItems)) {
      total += this._bill.billItems.reduce((accumulator, billItem, index, array) => {
        let itemPrice = this.calculateEditedItemPrice(billItem)
        if (type == 'item')
          itemPrice = this.calculateDiscountShotItem(billItem, itemPrice)

/*         if (!!billItem.editPriceType && !!billItem.editPriceValue && !billItem.ghost) {
          const singelItemDiscount = this.calculateEditedItemPrice(billItem)
          const totalItemPrice = parseInt(billItem.quantity) * parseFloat(singelItemDiscount)
          return Math.round((accumulator + (Math.round(totalItemPrice * 100) / 100)) * 100) / 100
        } else  */if (!billItem.ghost) {
          const totalItemPrice = parseInt(billItem.quantity) * parseFloat(itemPrice)
          return Math.round((accumulator + (Math.round(totalItemPrice * 100) / 100)) * 100) / 100
        } else {
          const realQuantity = parseInt(billItem.quantity) - parseInt(billItem.ghostQuantity)
          const totalItemPrice = parseInt(realQuantity) * parseFloat(itemPrice)
          return Math.round((accumulator + (Math.round(totalItemPrice * 100) / 100)) * 100) / 100
        }
      }, 0)
    }

    if (type == 'ghost' && !!this._bill) {
      total += this._bill.billServices.reduce((accumulator, billService, index, array) => {

        let servicePrice = Number(this.calculateEditedPrice(billService))
/*         servicePrice = !!billService.discountType && !!billService.discountValue ? 
          this.calculateDiscountShot(billService, servicePrice) : servicePrice */

/*         if (!!billService.editPriceType && !!billService.editPriceValue && billService.ghost) {
          let singleServiceDiscount = this.calculateEditedPrice(billService)
          if (!!billService.discountType && !!billService.discountValue) {
            singleServiceDiscount = this.calculateDiscountShot(billService, singleServiceDiscount)
          }
          const totalServicePrice = parseInt(billService.quantity) * parseFloat(singleServiceDiscount)
          return Math.round((accumulator + (Math.round(totalServicePrice * 100) / 100)) * 100) / 100
        } else  */if (billService.ghost) {
          const totalServicePrice = (parseInt(billService.quantity) * parseFloat(servicePrice)) //- Number(billService.discount)
          return Math.round((accumulator + (Math.round(totalServicePrice * 100) / 100)) * 100) / 100
        }
        return 0
      }, 0)

      total += this._bill.billItems.reduce((accumulator, billItem, index, array) => {

        let itemPrice = Number(this.calculateEditedItemPrice(billItem))
/*         servicePrice = !!billService.discountType && !!billService.discountValue ? 
          this.calculateDiscountShot(billService, servicePrice) : servicePrice */

/*         if (!!billService.editPriceType && !!billService.editPriceValue && billService.ghost) {
          let singleServiceDiscount = this.calculateEditedPrice(billService)
          if (!!billService.discountType && !!billService.discountValue) {
            singleServiceDiscount = this.calculateDiscountShot(billService, singleServiceDiscount)
          }
          const totalServicePrice = parseInt(billService.quantity) * parseFloat(singleServiceDiscount)
          return Math.round((accumulator + (Math.round(totalServicePrice * 100) / 100)) * 100) / 100
        } else  */if (billItem.ghost) {
          const totalItemPrice = (parseInt(billItem.quantity) * parseFloat(itemPrice)) //- Number(billService.discount)
          return Math.round((accumulator + (Math.round(totalItemPrice * 100) / 100)) * 100) / 100
        }
        return 0
      }, 0)
    }

    return Number(Number(total).toFixed(2))
  }

  calculateDiscount() {
    let areServicesPresent = false
    let areItemsPresent = false
    let discountShotOnServices = false
    let discountShotOnItems = false
    let servicesTotalExternalDiscount = 0
    let itemsTotalExternalDiscount = 0

    if (!!this._bill) {
      areServicesPresent = !!this._bill.billServices ? this._bill.billServices.length > 0 : false
      areItemsPresent = !!this._bill.billItems ? this._bill.billItems.length > 0 : false

      if (areServicesPresent) {
        discountShotOnServices = this._bill.billServices.some((el) => {
          return !!el.discountType && !!el.discountValue
        })
      }

      if (areItemsPresent) {
        discountShotOnItems = this._bill.billItems.some((el) => {
          return !!el.discountType && !!el.discountValue
        })
      }

      if (areServicesPresent)
        servicesTotalExternalDiscount = this._bill.billServices.reduce((accumulator, bi) => {
          let localPrice = this.calculateEditedPrice(bi)
          let price = this.calculateDiscountShot(bi, localPrice)
          return accumulator + Number(((localPrice - price) * Number(bi.quantity)).toFixed(2))
        }, 0)

      if (areItemsPresent)
        itemsTotalExternalDiscount = this._bill.billItems.reduce((accumulator, bi) => {
          let localPrice = this.calculateEditedItemPrice(bi)
          let price = this.calculateDiscountShotItem(bi, localPrice)
          return accumulator + Number(((localPrice - price) * Number(bi.quantity)).toFixed(2))
        }, 0)
    }

    let totalExternalDiscount = servicesTotalExternalDiscount + itemsTotalExternalDiscount

    if (!!this._customerCard && !!areServicesPresent && !areItemsPresent && this._useCustomerCard) {
      const maxCardDiscount = Number(this._getCardMaxDiscountTotal())
      const decimalServiceDiscount = !!this._customerCard.serviceDiscounts ? (Number(this._customerCard.serviceDiscounts) / 100).toFixed(2) : 0
      const decimalItemDiscount = !!this._customerCard.productsDiscounts ? (Number(this._customerCard.productsDiscounts) / 100).toFixed(2) : 0

      if (maxCardDiscount <= (Number(this.total) - Number(servicesTotalExternalDiscount))) {
        return (Math.max(0, (Number(maxCardDiscount) - Number(this._customerCard.remaining))) + totalExternalDiscount).toFixed(2)
      } else {
        let servicesTotal = this._bill.billServices.reduce((accumulator, bi) => {
          let price = this.calculateEditedPrice(bi)
          price = this.calculateDiscountShot(bi, price)
          return accumulator + Number(((Number(bi.quantity) * Number(price).toFixed(2))))
        }, 0)

        let itemsTotal = this._bill.billItems.reduce((accumulator, bi) => {
          let price = this.calculateEditedItemPrice(bi)
          price = this.calculateDiscountShotItem(bi, price)
          return accumulator + Number(((Number(bi.quantity) * Number(price).toFixed(2))))
        }, 0)

        const subTotalServices = Number(Number(Number(servicesTotal) * (1 - Number(decimalServiceDiscount))).toFixed(2))
        const subTotalItems = Number(Number(Number(itemsTotal) * (1 - Number(decimalItemDiscount))).toFixed(2))
        return ((this.total - subTotalServices - subTotalItems)).toFixed(2)
      }
    } else if (!!this._customerCard && !!areItemsPresent && !areServicesPresent && this._useCustomerCard) {
      const maxCardDiscount = Number(this._getCardMaxDiscountTotal())
      const decimalServiceDiscount = !!this._customerCard.serviceDiscounts ? (Number(this._customerCard.serviceDiscounts) / 100).toFixed(2) : 0
      const decimalItemDiscount = !!this._customerCard.productsDiscounts ? (Number(this._customerCard.productsDiscounts) / 100).toFixed(2) : 0
      if (maxCardDiscount <= (Number(this.total) - Number(itemsTotalExternalDiscount))) {
        return (Math.max(0, (Number(maxCardDiscount).toFixed(2) - Number(this._customerCard.remaining).toFixed(2))) + totalExternalDiscount).toFixed(2)
      } else {
        let servicesTotal = this._bill.billServices.reduce((accumulator, bi) => {
          let price = this.calculateEditedPrice(bi)
          price = this.calculateDiscountShot(bi, price)
          return accumulator + Number(((Number(bi.quantity) * Number(price).toFixed(2))))
        }, 0)

        let itemsTotal = this._bill.billItems.reduce((accumulator, bi) => {
          let price = this.calculateEditedItemPrice(bi)
          price = this.calculateDiscountShotItem(bi, price)
          return accumulator + Number(((Number(bi.quantity) * Number(price).toFixed(2))))
        }, 0)

        const subTotalServices = Number(Number(Number(servicesTotal) * (1 - Number(decimalServiceDiscount))).toFixed(2))
        const subTotalItems = Number(Number(Number(itemsTotal) * (1 - Number(decimalItemDiscount))).toFixed(2))
        return Number(((Number(this.total) - subTotalServices - subTotalItems)).toFixed(2))
      }
    } else if (!!this._customerCard && !!areServicesPresent && !!areItemsPresent && this._useCustomerCard) {
      let totalCardDiscount = 0
      let discountedBillEntities = [...this.discountedBillEntities]
      if (!!discountedBillEntities) {
        for (let i = 0; i < discountedBillEntities.length; i++) {
          totalCardDiscount = !!Number(discountedBillEntities[i].discount) ? Number(discountedBillEntities[i].discount) + Number(totalCardDiscount) : Number(totalCardDiscount)
        }
      }
      return Number((totalCardDiscount).toFixed(2))
    } else if (this._promosCustomers.length > 0 && this._usePromo) {
      let isPromoWithFreeServices = !!this.promosCustomerUsed && this.promosCustomerUsed.promo.itemGained == 'services'

      if (isPromoWithFreeServices) {
        return Number(Number(this._getPromosDiscount()).toFixed(2))
      } else if (!!this._getPromosDiscount() && this._getPromosDiscount().min !== undefined && this._getPromosDiscount().min !== null) {
        return Number(Number(this._getPromosDiscount().min).toFixed(2))
      } else {
        return Number(Number(this._getPromosDiscount()).toFixed(2)) + Number(totalExternalDiscount.toFixed(2))
      }
    } else if (discountShotOnServices || discountShotOnItems) {
      let servicesTotal = this._bill.billServices.reduce((accumulator, bi) => {
        let price = this.calculateEditedPrice(bi)
        price = this.calculateDiscountShot(bi, price)
        return accumulator + Number(((Number(bi.quantity) * Number(price).toFixed(2))))
      }, 0)

      let itemsTotal = this._bill.billItems.reduce((accumulator, bi) => {
        let price = this.calculateEditedItemPrice(bi)
        price = this.calculateDiscountShotItem(bi, price)
        return accumulator + Number(((Number(bi.quantity) * Number(price).toFixed(2))))
      }, 0)
      return Number((Number(this.total) + Number(this.totalGhost)) - Number(servicesTotal) - Number(itemsTotal)).toFixed(2)
    } else {
      return 0
    }
  }

  calculateRemainingPromo() {
    if (this._promosCustomers.length > 0 && this._usePromo && this._promosCustomers.find((promoCustomer) => 
      !!promoCustomer.selected).promo.itemGained == 'amount') {
      return !this._getPromosDiscount().remaining ? 0 : Number(Number(this._getPromosDiscount().remaining).toFixed(2))
    } else {
      return undefined
    }
  }

  get discountedBillServices() {
    if (!!this._bill && !!this._bill.billServices && Array.isArray(this._bill.billServices)) {
      if (!!this._useCustomerCard) {
        // DISCOUNT SERVICES WITH CUSTOMER CARD
        const maxCardDiscount = this._getCardMaxDiscountTotal()
        let billServicesWithDiscount = [...this._bill.billServices]
        let virtualCardRemaining = Number(maxCardDiscount);
        let partialDiscount = virtualCardRemaining - this._customerCard.remaining

        for (let i = 0; i < billServicesWithDiscount.length; i += 1) {
          let localPrice = this.calculateEditedPrice(billServicesWithDiscount[i])
          let externalDiscount = 0
          if (!!billServicesWithDiscount[i].discountType && !!billServicesWithDiscount[i].discountValue) {
            let oldLocalPrice = localPrice
            localPrice = this.calculateDiscountShot(billServicesWithDiscount[i], localPrice)
            externalDiscount = oldLocalPrice - localPrice
          }
          const totalServicePrice = Number((parseInt(billServicesWithDiscount[i].quantity) * localPrice).toFixed(2))
          externalDiscount = externalDiscount * Number(billServicesWithDiscount[i].quantity)

          if (virtualCardRemaining != 0 && totalServicePrice >= virtualCardRemaining) {
            const decimalServiceDiscount = !!this._customerCard.serviceDiscounts ? (Number(this._customerCard.serviceDiscounts) / 100).toFixed(2) : 0
            let discount = (Number(totalServicePrice).toFixed(2) * (Number(decimalServiceDiscount)).toFixed(2)).toFixed(2)

            billServicesWithDiscount[i].discount = Math.min(Number(partialDiscount) + Number(externalDiscount), Number(discount) + Number(externalDiscount)).toFixed(2)

            let discountPerc = 100 * Number(billServicesWithDiscount[i].discount) / this.calculateEditedPrice(billServicesWithDiscount[i]) / Number(billServicesWithDiscount[i].quantity)
            billServicesWithDiscount[i].discountDescription = 'Sconto ' + discountPerc.toFixed(2) + '%'

            partialDiscount = 0
            virtualCardRemaining = 0
          } else if (virtualCardRemaining != 0 && totalServicePrice < virtualCardRemaining && !!this._customerCard.serviceDiscounts) {
            const decimalServiceDiscount = !!this._customerCard.serviceDiscounts ? (Number(this._customerCard.serviceDiscounts) / 100).toFixed(2) : 0
            const discount = Number((Number(Number(totalServicePrice).toFixed(2)) * (Number(decimalServiceDiscount))).toFixed(2))
            virtualCardRemaining = Number(Number((virtualCardRemaining - Number(Number(totalServicePrice).toFixed(2)))).toFixed(2))
            partialDiscount = Number(Number(partialDiscount) - discount).toFixed(2)
            billServicesWithDiscount[i].discount = (discount + Number(externalDiscount)).toFixed(2)

            let discountPerc = 100 * Number(billServicesWithDiscount[i].discount) / this.calculateEditedPrice(billServicesWithDiscount[i]) / Number(billServicesWithDiscount[i].quantity)
            billServicesWithDiscount[i].discountDescription = 'Sconto ' + discountPerc.toFixed(2) + '%'
          } else if (virtualCardRemaining === 0) {
            billServicesWithDiscount[i].discount = (0 + Number(externalDiscount)).toFixed(2)

            let discountPerc = 100 * Number(billServicesWithDiscount[i].discount) / this.calculateEditedPrice(billServicesWithDiscount[i]) / Number(billServicesWithDiscount[i].quantity)
            billServicesWithDiscount[i].discountDescription = 'Sconto ' + discountPerc.toFixed(2) + '%'
          }
        }

        return billServicesWithDiscount
      } else if (this._promosCustomers.length > 0 && this._usePromo) {
        // DISCOUNT SERVICES WITH PROMO
        let billServicesWithDiscount = [...this._bill.billServices]
        const promosCustomer = this.promosCustomerUsed
        const promo = !!promosCustomer ? promosCustomer.promo : undefined
        if (promosCustomer.usageProgress == 'loading')
          return billServicesWithDiscount

        if (promo.itemGained == 'discount' && promo.allServicesValid) {
          for (let k = 0; k < billServicesWithDiscount.length; k++) {
            const decimalDiscount = Number((Number(promo.discount) / 100).toFixed(2))
            let localPrice = this.calculateEditedPrice(billServicesWithDiscount[k])
            let externalDiscount = 0
            if (!!billServicesWithDiscount[k].discountType && !!billServicesWithDiscount[k].discountValue) {
              let oldLocalPrice = localPrice
              localPrice = this.calculateDiscountShot(billServicesWithDiscount[k], localPrice)
              externalDiscount = oldLocalPrice - localPrice
            }
            const totalPrice = Number((Number(billServicesWithDiscount[k].quantity) * Number(localPrice))).toFixed(2)
            externalDiscount = externalDiscount * Number(billServicesWithDiscount[k].quantity)
            //const totalPrice = Number(Number(localPrice)).toFixed(2)
            billServicesWithDiscount[k].discount = Number((Number(totalPrice).toFixed(2) * Number(decimalDiscount)) + externalDiscount).toFixed(2)

            let discountPerc = 100 * Number(billServicesWithDiscount[k].discount) / this.calculateEditedPrice(billServicesWithDiscount[k]) / Number(billServicesWithDiscount[k].quantity)
            billServicesWithDiscount[k].discountDescription = 'Sconto ' + discountPerc.toFixed(2) + '%'
          }
          return billServicesWithDiscount
        }
        else if (promo.itemGained == 'discount' && (!promo.gainedItems || !promo.gainedItems.length > 0)) {
          const decimalDiscount = 0 //Number((Number(promo.discount) / 100).toFixed(2))
          return billServicesWithDiscount.map(bs => {
            let localPrice = this.calculateEditedPrice(bs)
            let externalDiscount = 0
            if (!!bs.discountType && !!bs.discountValue) {
              let oldLocalPrice = localPrice
              localPrice = this.calculateDiscountShot(bs, localPrice)
              externalDiscount = oldLocalPrice - localPrice
            }
            const totalPrice = Number((Number(bs.quantity) * Number(localPrice)).toFixed(2))
            externalDiscount = externalDiscount * Number(bs.quantity)
            //const totalPrice = (Number(localPrice)).toFixed(2)
            bs.discount = Number((Number(Number(Number((totalPrice * decimalDiscount).toFixed(2)) + externalDiscount).toFixed(2)) / Number(bs.quantity)).toFixed(2))

            let discountPerc = 100 * Number(bs.discount) / this.calculateEditedPrice(bs) / Number(bs.quantity)
            bs.discountDescription = 'Sconto ' + discountPerc.toFixed(2) + '%'
            return bs
          })
        } else if (promo.itemGained == 'discount' && !!promo.gainedItems && promo.gainedItems.length > 0) {
          let servicesDiscounted = promosCustomer.promosCustomerItems.filter(el => el.usageProgress != 'target');
          const decimalDiscount = Number((Number(promo.discount) / 100).toFixed(2))
          for (let i = 0; i < promo.gainedItems.length; i++) {
            if ((promo.gainedItems[i].quantity === undefined || promo.gainedItems[i].quantity === null) 
              && promo.gainType == 'price') {
              promo.gainedItems[i].quantity = Infinity
            }

            for (let k = 0; k < billServicesWithDiscount.length; k++) {
              if (!!billServicesWithDiscount[k] && promo.gainedItems[i].serviceId == billServicesWithDiscount[k].serviceId) {
                const sd = servicesDiscounted.find(el => Number(el.promosItem.serviceId) == Number(billServicesWithDiscount[k].serviceId))
                if (!sd) {
                  //FIRST TIME USE DISCOUNT PROMO ON SINGLE SERVICES
                  if (promo.gainedItems[i].quantity >= billServicesWithDiscount[k].quantity) {
                    let localPrice = this.calculateEditedPrice(billServicesWithDiscount[k])
                    let externalDiscount = 0
                    if (!!billServicesWithDiscount[k].discountType && !!billServicesWithDiscount[k].discountValue) {
                      let oldLocalPrice = localPrice
                      localPrice = this.calculateDiscountShot(billServicesWithDiscount[k], localPrice)
                      externalDiscount = oldLocalPrice - localPrice
                    }
                    const totalPrice = Number((Number(billServicesWithDiscount[k].quantity) * Number(localPrice))).toFixed(2)
                    externalDiscount = externalDiscount * Number(billServicesWithDiscount[k].quantity)
                    //const totalPrice = Number(Number(localPrice).toFixed(2))
                    billServicesWithDiscount[k].discount = Number((Number(totalPrice).toFixed(2) * Number(decimalDiscount)) + externalDiscount).toFixed(2)
                    let discountPerc = 100 * Number(billServicesWithDiscount[k].discount) / this.calculateEditedPrice(billServicesWithDiscount[k]) / Number(billServicesWithDiscount[k].quantity)
                    billServicesWithDiscount[k].discountDescription = 'Sconto ' + discountPerc.toFixed(2) + '%'
                  } else if (promo.gainedItems[i].quantity < billServicesWithDiscount[k].quantity) {
                    let localPrice = this.calculateEditedPrice(billServicesWithDiscount[k])
                    let externalDiscount = 0
                    if (!!billServicesWithDiscount[k].discountType && !!billServicesWithDiscount[k].discountValue) {
                      let oldLocalPrice = localPrice
                      localPrice = this.calculateDiscountShot(billServicesWithDiscount[k], localPrice)
                      externalDiscount = oldLocalPrice - localPrice
                    }
                    const totalPrice = Number((Number(promo.gainedItems[i].quantity) * Number(localPrice))).toFixed(2)
                    externalDiscount = externalDiscount * Number(promo.gainedItems[i].quantity)
                    //const totalPrice = Number(Number(localPrice).toFixed(2))
                    billServicesWithDiscount[k].discount = Number((Number(totalPrice).toFixed(2) * Number(decimalDiscount)) + externalDiscount).toFixed(2)

                    let discountPerc = 100 * Number(billServicesWithDiscount[k].discount) / this.calculateEditedPrice(billServicesWithDiscount[k]) / Number(billServicesWithDiscount[k].quantity)
                    billServicesWithDiscount[k].discountDescription = 'Sconto ' + discountPerc.toFixed(2) + '%'
                  }
                } else {
                  //ALREADY USED DISCOUNT PROMO ON SINGLE SERVICE
                  if (sd.promosItem.quantity === undefined || sd.promosItem.quantity === null) {
                    sd.promosItem.quantity = Infinity
                  }

                  const promoItem = promo.gainedItems.find(el => Number(el.serviceId) == Number(billServicesWithDiscount[k].serviceId))
                  if ((Number(promoItem.quantity) + Number(sd.usedQuantity)) >= Number(sd.promosItem.quantity)) {
                    const remainingQuantity = Number(sd.promosItem.quantity) - Number(sd.usedQuantity)
                    const itemToDiscount = Math.min(remainingQuantity, billServicesWithDiscount[k].quantity)
                    let localPrice = this.calculateEditedPrice(billServicesWithDiscount[k])
                    let externalDiscount = 0
                    if (!!billServicesWithDiscount[k].discountType && !!billServicesWithDiscount[k].discountValue) {
                      let oldLocalPrice = localPrice
                      localPrice = this.calculateDiscountShot(billServicesWithDiscount[k], localPrice)
                      externalDiscount = oldLocalPrice - localPrice
                    }
                    const totalPrice = Number((Number(itemToDiscount) * Number(localPrice))).toFixed(2)
                    externalDiscount = externalDiscount * Number(itemToDiscount)
                    //const totalPrice = Number(Number(localPrice).toFixed(2))
                    billServicesWithDiscount[k].discount = Number((Number(totalPrice).toFixed(2) * Number(decimalDiscount)) + externalDiscount).toFixed(2)

                    let discountPerc = 100 * Number(billServicesWithDiscount[k].discount) / this.calculateEditedPrice(billServicesWithDiscount[k]) / Number(billServicesWithDiscount[k].quantity)
                    billServicesWithDiscount[k].discountDescription = 'Sconto ' + discountPerc.toFixed(2) + '%'
                  } else if ((Number(promoItem.quantity) + Number(sd.usedQuantity)) < Number(sd.promosItem.quantity)) {
                    const remainingQuantity = Number(promoItem.quantity) - Number(sd.usedQuantity)
                    let localPrice = this.calculateEditedPrice(billServicesWithDiscount[k])
                    let externalDiscount = 0
                    if (!!billServicesWithDiscount[k].discountType && !!billServicesWithDiscount[k].discountValue) {
                      let oldLocalPrice = localPrice
                      localPrice = this.calculateDiscountShot(billServicesWithDiscount[k], localPrice)
                      externalDiscount = oldLocalPrice - localPrice
                    }
                    const totalPrice = Number((Number(remainingQuantity) * Number(localPrice))).toFixed(2)
                    externalDiscount = externalDiscount * Number(remainingQuantity)
                    //const totalPrice = Number(Number(localPrice).toFixed(2))
                    billServicesWithDiscount[k].discount = Number((Number(totalPrice).toFixed(2) * Number(decimalDiscount)) + externalDiscount).toFixed(2)

                    let discountPerc = 100 * Number(billServicesWithDiscount[k].discount) / this.calculateEditedPrice(billServicesWithDiscount[k]) / Number(billServicesWithDiscount[k].quantity)
                    billServicesWithDiscount[k].discountDescription = 'Sconto ' + discountPerc.toFixed(2) + '%'
                  } else {
                    let localPrice = this.calculateEditedPrice(billServicesWithDiscount[k])
                    let externalDiscount = 0
                    if (!!billServicesWithDiscount[k].discountType && !!billServicesWithDiscount[k].discountValue) {
                      let oldLocalPrice = localPrice
                      localPrice = this.calculateDiscountShot(billServicesWithDiscount[k], localPrice)
                      externalDiscount = oldLocalPrice - localPrice
                    }

                    billServicesWithDiscount[k].discount = (0 + externalDiscount).toFixed(2)

                    let discountPerc = 100 * Number(billServicesWithDiscount[k].discount) / Number(billServicesWithDiscount[k].service.price) / Number(billServicesWithDiscount[k].quantity)
                    billServicesWithDiscount[k].discountDescription = 'Sconto ' + discountPerc.toFixed(2) + '%'
                  }
                }
              }
              if (promo.gainedItems.find(el => Number(el.serviceId) == Number(billServicesWithDiscount[k].serviceId)) === undefined) {
                let localPrice = this.calculateEditedPrice(billServicesWithDiscount[k])
                let externalDiscount = 0
                if (!!billServicesWithDiscount[k].discountType && !!billServicesWithDiscount[k].discountValue) {
                  let oldLocalPrice = localPrice
                  localPrice = this.calculateDiscountShot(billServicesWithDiscount[k], localPrice)
                  externalDiscount = oldLocalPrice - localPrice
                }

                billServicesWithDiscount[k].discount = (0 + externalDiscount).toFixed(2)

                let discountPerc = 100 * Number(billServicesWithDiscount[k].discount) / Number(billServicesWithDiscount[k].service.price) / Number(billServicesWithDiscount[k].quantity)
                billServicesWithDiscount[k].discountDescription = 'Sconto ' + discountPerc.toFixed(2) + '%'
              }
            }
          }
          return billServicesWithDiscount
        } else if (promo.itemGained == 'amount') {
          let maxDiscount = Number(promo.amount)

          billServicesWithDiscount.sort((a, b) => {
            if (!!a.service && !!b.service) {
              return Number(b.service.price) - Number(a.service.price)
            } else {
              return 0
            }
          })

          /*
          KEEP COMMENT
          Se un domani volgiono gli sconti singoli e non più comulativi quando c'è una promo a valore
          for (let k = 0; k < billServicesWithDiscount.length && !!maxDiscount; k++) {
            let localPrice = this.calculateEditedPrice(billServicesWithDiscount[k])
            if (!!billServicesWithDiscount[k].discountType && !!billServicesWithDiscount[k].discountValue) {
              localPrice = this.calculateDiscountShot(billServicesWithDiscount[k], localPrice)
            }
            const totalPrice = Number((Number(billServicesWithDiscount[k].quantity) * Number(localPrice))).toFixed(2)
            if (maxDiscount >= totalPrice) {
              billServicesWithDiscount[k].discount = totalPrice
              maxDiscount = Number(maxDiscount) - Number(totalPrice)
            } else {
              billServicesWithDiscount[k].discount = maxDiscount
              maxDiscount = 0
            }
          }*/

          for (let k = 0; k < billServicesWithDiscount.length && !!maxDiscount; k++) {
            let localPrice = this.calculateEditedPrice(billServicesWithDiscount[k])
            let externalDiscount = 0
            if (!!billServicesWithDiscount[k].discountType && !!billServicesWithDiscount[k].discountValue) {
              let oldLocalPrice = localPrice
              localPrice = this.calculateDiscountShot(billServicesWithDiscount[k], localPrice)
              externalDiscount = oldLocalPrice - localPrice
            }
            billServicesWithDiscount[k].discount = externalDiscount.toFixed(2)

            let discountPerc = 100 * Number(billServicesWithDiscount[k].discount) / this.calculateEditedPrice(billServicesWithDiscount[k]) / Number(billServicesWithDiscount[k].quantity)
            billServicesWithDiscount[k].discountDescription = 'Sconto ' + discountPerc.toFixed(2) + '%'
          }

          return billServicesWithDiscount
        } else if (promo.itemGained == 'services') {
          let servicesConsumed = promosCustomer.promosCustomerItems.filter(el => el.usageProgress != 'target');
          let billServicesWithDiscount = [...this._bill.billServices]
          for (let i = 0; i < billServicesWithDiscount.length; i += 1) {
            const sc = servicesConsumed.find(el => Number(el.promosItem.serviceId) == Number(billServicesWithDiscount[i].serviceId))
            const promoItem = !!promo.gainedItems ? promo.gainedItems.find(el => Number(el.serviceId) == Number(billServicesWithDiscount[i].serviceId)) : undefined
            if (!!promoItem) {
              const remainingQuantity = !!sc ? Number(promoItem.quantity) - Number(sc.usedQuantity) : Number(promoItem.quantity)
              const discountedQuantity = Math.min(remainingQuantity, billServicesWithDiscount[i].quantity)
              billServicesWithDiscount[i].discount = Number(Number(Number(billServicesWithDiscount[i].service.price).toFixed(2)) * discountedQuantity).toFixed(2)

              let discountPerc = 100 * Number(billServicesWithDiscount[i].discount) / this.calculateEditedPrice(billServicesWithDiscount[i]) / Number(billServicesWithDiscount[i].quantity)
              billServicesWithDiscount[i].discountDescription = 'Sconto ' + discountPerc.toFixed(2) + '%'
            } else {
              let localPrice = this.calculateEditedPrice(billServicesWithDiscount[i])
              let externalDiscount = 0
              if (!!billServicesWithDiscount[i].discountType && !!billServicesWithDiscount[i].discountValue) {
                let oldLocalPrice = localPrice
                localPrice = this.calculateDiscountShot(billServicesWithDiscount[i], localPrice)
                externalDiscount = oldLocalPrice - localPrice
              }
              billServicesWithDiscount[i].discount = (0 + Number(externalDiscount)).toFixed(2)

              let discountPerc = 100 * Number(billServicesWithDiscount[i].discount) / this.calculateEditedPrice(billServicesWithDiscount[i]) / Number(billServicesWithDiscount[i].quantity)
              billServicesWithDiscount[i].discountDescription = 'Sconto ' + discountPerc.toFixed(2) + '%'
            }
          }
          return billServicesWithDiscount
        } else {
          return billServicesWithDiscount.map(bs => {
            let localPrice = this.calculateEditedPrice(bs)
            let externalDiscount = 0
            if (!!bs.discountType && !!bs.discountValue) {
              let oldLocalPrice = localPrice
              localPrice = this.calculateDiscountShot(bs, localPrice)
              externalDiscount = oldLocalPrice - localPrice
            }
            bs.discount = (0 + Number(externalDiscount)).toFixed(2)


            let discountPerc = 100 * Number(bs.discount) / this.calculateEditedPrice(bs) / Number(bs.quantity)
            bs.discountDescription = 'Sconto ' + discountPerc.toFixed(2) + '%'
            return bs
          })
        }
      } else if (this._bill.billServices.some((el) => {
        return !!el.discountType && !!el.discountValue
      })) {
        let billServicesWithDiscount = [...this._bill.billServices]
        return billServicesWithDiscount.map(bs => {
          let localPrice = this.calculateEditedPrice(bs)
          bs.discount = (Number(Number(localPrice) - this.calculateDiscountShot(bs, localPrice)) * Number(bs.quantity)).toFixed(2)


          let discountPerc = 100 * Number(bs.discount) / this.calculateEditedPrice(bs) / Number(bs.quantity)
          bs.discountDescription = 'Sconto ' + discountPerc.toFixed(2) + '%'
          return bs
        })
      } else {
        // DISCOUNT SERVICES WITH NO DISCOUNTS

        let billServicesWithDiscount = [...this._bill.billServices]
        return billServicesWithDiscount.map(bs => {
          bs.discount = 0
          return bs
        })
      }
    } else {
      return []
    }
  }

  get discountedBillItems() {
    if (!!this._bill && !!this._bill.billItems && Array.isArray(this._bill.billItems)) {
      if (this._promosCustomers.length > 0 && this._usePromo) {
        let billItemsWithDiscount = [...this._bill.billItems]
        const promosCustomer = this.promosCustomerUsed
        const promo = !!promosCustomer ? promosCustomer.promo : undefined
        if (promo.itemGained == 'discount' && !!promo.allItemsValid) {
          const decimalDiscount = Number((Number(promo.discount) / 100).toFixed(2))
          return billItemsWithDiscount.map(bi => {
            let localPrice = this.calculateEditedItemPrice(bi)
            let externalDiscount = 0
            if (!!bi.discountType && !!bi.discountValue) {
              let oldLocalPrice = localPrice
              localPrice = this.calculateDiscountShotItem(bi, localPrice)
              externalDiscount = oldLocalPrice - localPrice
            }

            const totalPrice = Number((Number(bi.quantity) * Number(localPrice)).toFixed(2))
            externalDiscount = externalDiscount * Number(bi.quantity)
            bi.discount = (Number((totalPrice * decimalDiscount).toFixed(2)) + Number(externalDiscount)).toFixed(2)
            let discountPerc = 100 * Number(bi.discount) / this.calculateDiscountShotItem(bi) / Number(bi.quantity)
            bi.discountDescription = 'Sconto ' + discountPerc.toFixed(2) + '%'
            return bi
          })
        } else {
          billItemsWithDiscount.sort((a, b) => {
            if (!!a.item && !!b.item) {
              return Number(b.item.priceSelling) - Number(a.item.priceSelling)
            } else {
              return 0
            }
          })

          return billItemsWithDiscount.map(bi => {
            let localPrice = this.calculateEditedItemPrice(bi)
            bi.discount = ((Number(localPrice) - this.calculateDiscountShotItem(bi, localPrice)).toFixed(2) * Number(bi.quantity)).toFixed(2)

            let discountPerc = 100 * Number(bi.discount) / this.calculateDiscountShotItem(bi) / Number(bi.quantity)
            bi.discountDescription = 'Sconto ' + discountPerc.toFixed(2) + '%'
            return bi
          })
        }
      } else if (this._useCustomerCard) {
        const maxCardDiscount = this._getCardMaxDiscountTotal()
        let billItemsWithDiscount = [...this._bill.billItems]
        let virtualCardRemaining = Number(maxCardDiscount)
        let partialDiscount = virtualCardRemaining - this._customerCard.remaining;

        for (let i = 0; i < billItemsWithDiscount.length; i += 1) {
          let localPrice = this.calculateEditedItemPrice(billItemsWithDiscount[i])
          let externalDiscount = 0
          if (!!billItemsWithDiscount[i].discountType && !!billItemsWithDiscount[i].discountValue) {
            let oldLocalPrice = localPrice
            localPrice = this.calculateDiscountShotItem(billItemsWithDiscount[i], localPrice)
            externalDiscount = oldLocalPrice - localPrice
          }

          const totalItemPrice = Number((parseInt(billItemsWithDiscount[i].quantity) * localPrice).toFixed(2))
          externalDiscount = externalDiscount * Number(billItemsWithDiscount[i].quantity)

          if (virtualCardRemaining != 0 && totalItemPrice > virtualCardRemaining) {
            const decimalItemDiscount = !!this._customerCard.productsDiscounts ? (Number(this._customerCard.productsDiscounts) / 100).toFixed(2) : 0
            let discount = Number((Number(totalItemPrice).toFixed(2) * (Number(decimalItemDiscount)).toFixed(2)).toFixed(2))

            billItemsWithDiscount[i].discount = Number(Math.min(Number(partialDiscount) + Number(externalDiscount), Number(discount) + Number(externalDiscount)).toFixed(2)).toFixed(2)

            let discountPerc = 100 * Number(billItemsWithDiscount[i].discount) / this.calculateEditedItemPrice(billItemsWithDiscount[i]) / Number(billItemsWithDiscount[i].quantity)
            billItemsWithDiscount[i].discountDescription = 'Sconto ' + discountPerc.toFixed(2) + '%'

            partialDiscount = 0
            virtualCardRemaining = 0
          } else if (virtualCardRemaining != 0 && totalItemPrice < virtualCardRemaining && !!this._customerCard.productsDiscounts) {

            const decimalItemDiscount = !!this._customerCard.productsDiscounts ? (Number(this._customerCard.productsDiscounts) / 100).toFixed(2) : 0
            const discount = (Number(totalItemPrice).toFixed(2) * (Number(decimalItemDiscount))).toFixed(2)
            virtualCardRemaining = Number(Number((virtualCardRemaining - Number(Number(totalItemPrice).toFixed(2)))).toFixed(2))
            partialDiscount = Number(Number(partialDiscount) - discount).toFixed(2)
            billItemsWithDiscount[i].discount = (Number(discount) + Number(externalDiscount)).toFixed(2)

            let discountPerc = 100 * Number(billItemsWithDiscount[i].discount) / this.calculateEditedItemPrice(billItemsWithDiscount[i]) / Number(billItemsWithDiscount[i].quantity)
            billItemsWithDiscount[i].discountDescription = 'Sconto ' + discountPerc.toFixed(2) + '%'
          } else if (virtualCardRemaining === 0) {
            billItemsWithDiscount[i].discount = (0 + Number(externalDiscount)).toFixed(2)

            let discountPerc = 100 * Number(billItemsWithDiscount[i].discount) / billItemsWithDiscount[i].item.priceSelling / Number(billItemsWithDiscount[i].quantity)
            billItemsWithDiscount[i].discountDescription = 'Sconto ' + discountPerc.toFixed(2) + '%'
          }
        }
        return billItemsWithDiscount
      } else if (this._bill.billItems.some((el) => {
        return !!el.discountType && !!el.discountValue
      })) {
        let billItemsWithDiscount = [...this._bill.billItems]
        return billItemsWithDiscount.map(bi => {
          let localPrice = this.calculateEditedItemPrice(bi)
          bi.discount = (Number(Number(localPrice) - this.calculateDiscountShotItem(bi, localPrice)) * Number(bi.quantity)).toFixed(2)

          let discountPerc = 100 * Number(bi.discount) / this.calculateEditedItemPrice(bi)
          bi.discountDescription = 'Sconto ' + discountPerc.toFixed(2) + '%'
          return bi
        })
      } else {
        let billItemsWithDiscount = [...this._bill.billItems]
        return billItemsWithDiscount.map(bi => {
          bi.discount = 0
          return bi
        })
      }
    } else {
      return []
    }
  }

  //handle discounts for services and items inside the customer card
  get discountedBillEntities() {
    const maxCardDiscount = this._getCardMaxDiscountTotal()
    let virtualCardRemaining = Number(maxCardDiscount);
    let partialDiscount = 0
    let maxCardDiscountRemaining = 0
    let billEntitiesWithDiscount = [];
    let doubleDiscount = false

    if (!!this._useCustomerCard && this._customerCard) {
      partialDiscount = virtualCardRemaining - this._customerCard.remaining
      partialDiscount = Math.max(0, Number(partialDiscount))

      if (maxCardDiscount == 0)
        doubleDiscount = true
      maxCardDiscountRemaining = Number(this.cardRemaining)
    }

    if (!!this._bill) {
      //services
      if (this._bill.billServices && Array.isArray(this._bill.billServices)) {
        if (this._useCustomerCard) {
          let billServicesWithDiscount = [...this._bill.billServices];
          for (let i = 0; i < billServicesWithDiscount.length; i += 1) {
            let localPrice = this.calculateEditedPrice(billServicesWithDiscount[i])
            let externalDiscount = 0
            if (!!billServicesWithDiscount[i].discountType && !!billServicesWithDiscount[i].discountValue) {
              let oldLocalPrice = localPrice
              localPrice = this.calculateDiscountShot(billServicesWithDiscount[i], localPrice)
              externalDiscount = Number(oldLocalPrice) - Number(localPrice)
            }
            const totalServicePrice = Number((parseInt(billServicesWithDiscount[i].quantity) * Number(localPrice)).toFixed(2))
            if (maxCardDiscountRemaining > 0 && totalServicePrice > maxCardDiscountRemaining) {
              const decimalServiceDiscount = !!this._customerCard.serviceDiscounts ? (Number(this._customerCard.serviceDiscounts) / 100).toFixed(2) : 0
              let discount = Number((Number(totalServicePrice) * (Number(decimalServiceDiscount))).toFixed(2))

              if (!doubleDiscount)
                billServicesWithDiscount[i].discount = Number(Math.min(partialDiscount, Number(discount) + externalDiscount).toFixed(2))
              else {
                discount = (Number(this._customerCard.remaining) * Number(decimalServiceDiscount)).toFixed(2)
                billServicesWithDiscount[i].discount = Number(Number(discount) + Number(externalDiscount)).toFixed(2)
              }
              let discountPerc = 100 * Number(billServicesWithDiscount[i].discount) / this.calculateEditedPrice(billServicesWithDiscount[i]) / Number(billServicesWithDiscount[i].quantity)
              billServicesWithDiscount[i].discountDescription = 'Sconto ' + discountPerc.toFixed(2) + '%'

              maxCardDiscountRemaining = 0
            }
            else if (maxCardDiscountRemaining > 0 && totalServicePrice < maxCardDiscountRemaining && !!this._customerCard.serviceDiscounts) {
              const decimalServiceDiscount = !!this._customerCard.serviceDiscounts ? (Number(this._customerCard.serviceDiscounts) / 100).toFixed(2) : 0
              maxCardDiscountRemaining = Number((maxCardDiscountRemaining - (Number(totalServicePrice).toFixed(2))).toFixed(2))
              let discount = Number((Number(totalServicePrice).toFixed(2) * (Number(decimalServiceDiscount)).toFixed(2)).toFixed(2))

              billServicesWithDiscount[i].discount = (discount + externalDiscount).toFixed(2)

              let discountPerc = 100 * Number(billServicesWithDiscount[i].discount) / this.calculateEditedPrice(billServicesWithDiscount[i]) / Number(billServicesWithDiscount[i].quantity)
              billServicesWithDiscount[i].discountDescription = 'Sconto ' + discountPerc.toFixed(2) + '%'

              partialDiscount -= discount
            } else {
              billServicesWithDiscount[i].discount = (0 + externalDiscount).toFixed(2)

              let discountPerc = 100 * Number(billServicesWithDiscount[i].discount) / this.calculateEditedPrice(billServicesWithDiscount[i]) / Number(billServicesWithDiscount[i].quantity)
              billServicesWithDiscount[i].discountDescription = 'Sconto ' + discountPerc.toFixed(2) + '%'
            }
            billServicesWithDiscount[i]['type'] = 'service'
            billServicesWithDiscount[i]['idKey'] = billServicesWithDiscount[i]['id'] + '_service'
            billEntitiesWithDiscount.push(billServicesWithDiscount[i])
          }
        }
      }

      //items
      if (!!this._bill.billItems && Array.isArray(this._bill.billItems)) {
        if (this._useCustomerCard) {
          let billItemsWithDiscount = [...this._bill.billItems];
          for (let i = 0; i < billItemsWithDiscount.length; i += 1) {
            let localPrice = this.calculateEditedItemPrice(billItemsWithDiscount[i])
            let externalDiscount = 0
            if (!!billItemsWithDiscount[i].discountType && !!billItemsWithDiscount[i].discountValue) {
              let oldLocalPrice = localPrice
              localPrice = this.calculateDiscountShotItem(billItemsWithDiscount[i], localPrice)
              externalDiscount = oldLocalPrice - localPrice
            }
            const totalItemPrice = Number((parseInt(billItemsWithDiscount[i].quantity) * localPrice).toFixed(2))

            if (maxCardDiscountRemaining > 0 && totalItemPrice > maxCardDiscountRemaining) {
              const decimalItemDiscount = !!this._customerCard.productsDiscounts ? (Number(this._customerCard.productsDiscounts) / 100).toFixed(2) : 0
              let discount = Number((Number(totalItemPrice).toFixed(2) * (Number(decimalItemDiscount)).toFixed(2)).toFixed(2))

              if (!doubleDiscount)
                billItemsWithDiscount[i].discount = Number(Math.min(partialDiscount + externalDiscount, Number(discount) + externalDiscount).toFixed(2))
              else {
                discount = (Number(this._customerCard.remaining) * Number(decimalItemDiscount)).toFixed(2)
                billItemsWithDiscount[i].discount = Number(Number(discount) + Number(externalDiscount)).toFixed(2)
              }

              let discountPerc = 100 * Number(billItemsWithDiscount[i].discount) / this.calculateEditedItemPrice(billItemsWithDiscount[i]) / Number(billItemsWithDiscount[i].quantity)
              billItemsWithDiscount[i].discountDescription = 'Sconto ' + discountPerc.toFixed(2) + '%'

              maxCardDiscountRemaining = 0
            }
            else if (maxCardDiscountRemaining > 0 && totalItemPrice < maxCardDiscountRemaining && !!this._customerCard.productsDiscounts) {
              const decimalItemDiscount = !!this._customerCard.productsDiscounts ? (Number(this._customerCard.productsDiscounts) / 100).toFixed(2) : 0
              maxCardDiscountRemaining = Number((maxCardDiscountRemaining - (Number(totalItemPrice).toFixed(2))).toFixed(2))
              let discount = Number((Number(totalItemPrice).toFixed(2) * (Number(decimalItemDiscount)).toFixed(2)).toFixed(2))
              billItemsWithDiscount[i].discount = (discount + externalDiscount).toFixed(2)

              let discountPerc = 100 * Number(billItemsWithDiscount[i].discount) / this.calculateEditedItemPrice(billItemsWithDiscount[i]) / Number(billItemsWithDiscount[i].quantity)
              billItemsWithDiscount[i].discountDescription = 'Sconto ' + discountPerc.toFixed(2) + '%'

              partialDiscount -= discount
            } else {
              billItemsWithDiscount[i].discount = (0 + externalDiscount).toFixed(2)

              let discountPerc = 100 * Number(billItemsWithDiscount[i].discount) / this.calculateEditedItemPrice(billItemsWithDiscount[i]) / Number(billItemsWithDiscount[i].quantity)
              billItemsWithDiscount[i].discountDescription = 'Sconto ' + discountPerc.toFixed(2) + '%'
            }
            billItemsWithDiscount[i]['type'] = 'item'
            billItemsWithDiscount[i]['idKey'] = billItemsWithDiscount[i]['id'] + '_item'
            billEntitiesWithDiscount.push(billItemsWithDiscount[i])
          }
        }
      }

      return billEntitiesWithDiscount

    } else {
      return []
    }
  }

  _getPromosDiscount() {
    if (this._promosCustomers.length > 0 && this._usePromo) {
      const promosCustomer = this.promosCustomerUsed
      const promo = !!promosCustomer ? promosCustomer.promo : undefined

      let localTotal = 0
      localTotal += this._bill.billServices.reduce((total, bs) => {
        let newPrice = Number(this.calculateEditedPrice(bs))
        return Number(total) + Number(Number(Number(this.calculateDiscountShot(bs, newPrice)) * Number(bs.quantity)).toFixed(2))
      }, 0)

      localTotal += this._bill.billItems.reduce((total, bs) => {
        let newPrice = Number(this.calculateEditedItemPrice(bs))
        return Number(total) + Number(Number(Number(this.calculateDiscountShotItem(bs, newPrice)) * Number(bs.quantity)).toFixed(2))
      }, 0)

      if (!promosCustomer || !promo) {
        return 0
      } else if (promosCustomer.usageProgress == 'finished' /*|| promosCustomer.usageProgress == 'loading'*/) {
        return 0
      } else if (promo.itemGained == 'discount' && !!promo.allItemsValid && !!promo.allServicesValid) {
        //DISCOUNT TOTAL
        const decimalDiscount = Number((Number(promo.discount) / 100).toFixed(2))
        return Number(Number(localTotal) * decimalDiscount).toFixed(2)
      } else if (promo.itemGained == 'discount' && !promo.allItemsValid && !!promo.allServicesValid) {
        //DISCOUNT ONLY SERVICE
        if (this.totalGhost > 0) {
          const decimalDiscount = Number((Number(promo.discount) / 100).toFixed(2))

          let totalPriceServices = this._bill.billServices.reduce((accumulator, bs) => {
            let localPrice = this.calculateEditedPrice(bs)
            if (!!bs.discountType && !!bs.discountValue)
              localPrice = this.calculateDiscountShot(bs, localPrice)

            return accumulator + Number(((Number(bs.quantity) * Number(localPrice)).toFixed(2)))
          }, 0)

          return Number(Number(Number(totalPriceServices) * decimalDiscount).toFixed(2))
        } else {
          const decimalDiscount = Number((Number(promo.discount) / 100).toFixed(2))
          return Number(Number(this.servicesTotal) * decimalDiscount).toFixed(2)
        }
      } else if (promo.itemGained == 'discount' && !!promo.allItemsValid && !promo.allServicesValid) {
        //DISCOUNT ONLY ITEMS
        const decimalDiscount = Number((Number(promo.discount) / 100).toFixed(2))
        return Number(Number(this.itemsTotal) * decimalDiscount).toFixed(2)
      } else if (promo.itemGained == 'amount' && !!promo.allItemsValid && !promo.allServicesValid) {
        //AMOUNT ONLY ITEMS
        const remainingAmount = Number(promosCustomer.remaining).toFixed(2)
        let billItemsWithAmount = [...this._bill.billItems.filter(el => !el.ghost)]
        let totalPriceItems = billItemsWithAmount.reduce((accumulator, bi) => {
          let localPrice = this.calculateEditedItemPrice(bi)
          if (!!bi.discountType && !!bi.discountValue)
            localPrice = this.calculateDiscountShotItem(bi, localPrice)

          return accumulator + Number(((Number(bi.quantity) * Number(localPrice)).toFixed(2)))
        }, 0)
        let min = Math.min(totalPriceItems, remainingAmount)
        return { "min": min, "remaining": Number(remainingAmount) - Number(min) }
      } else if (promo.itemGained == 'amount' && !!promo.allItemsValid && !!promo.allServicesValid) {
        //AMOUNT TOTAL(ITEMS AND SERVICES)
        let totalServiceDiscount = this.discountedBillServices.reduce((accumulator, current) => {
          return accumulator + (Number(current.discount) * Number(current.quantity))
        }, 0)

        let totalItemsDiscount = this.discountedBillItems.reduce((accumulator, current) => {
          return accumulator + (Number(current.discount) * Number(current.quantity))
        }, 0)

        const remainingAmount = Number(promosCustomer.remaining).toFixed(2)
        let min = Math.min(localTotal, remainingAmount)
        return { "min": min + totalServiceDiscount + totalItemsDiscount, "remaining": Number(remainingAmount) - Number(min) }
      } else if (promo.itemGained == 'amount' && !promo.allItemsValid && !!promo.allServicesValid) {
        //AMOUNT ONLY SERVICES
        let billItemsWithAmount = [...this._bill.billItems]
        let totalPriceItems = billItemsWithAmount.reduce((accumulator, bi) => {
          let localPrice = this.calculateEditedItemPrice(bi)
          if (!!bi.discountType && !!bi.discountValue)
            localPrice = this.calculateDiscountShotItem(bi, localPrice)

          return accumulator + Number(((Number(bi.quantity) * Number(localPrice)).toFixed(2)))
        }, 0)
        const remainingAmount = Number(promosCustomer.remaining).toFixed(2)
        let min = Math.min(Number(localTotal) - Number(totalPriceItems), remainingAmount)
        return { "min": min, "remaining": Number(remainingAmount) - Number(min) }
      } else if (promo.itemGained == 'amount' && !promo.allItemsValid && !promo.allServicesValid) {
        const discountedServicesIds = promo.gainedItems.filter((el) => !!el.serviceId).map((el) => el.serviceId.toString())
        let remaining = Number(promosCustomer.remaining)
        let discount = 0
        if (!!this._bill.billServices) {
          discount = this._bill.billServices.reduce((total, bs) => {
            if (discountedServicesIds.includes(bs.serviceId.toString())) {
              let servicePrice = Number(this.calculateEditedPrice(bs))
              if (!!bs.discountType && !!bs.discountValue) {
                servicePrice = this.calculateDiscountShot(bs, servicePrice)
              }

              let totalServiceDiscountedPrice = Number(Number(Number(servicePrice) * Number(bs.quantity)).toFixed(2))

              let discounted = Number(total + Math.min(Number(remaining), Number(totalServiceDiscountedPrice)))
              if (Number(remaining) >= Number(totalServiceDiscountedPrice)) remaining = Number((Number(remaining) - Number(totalServiceDiscountedPrice)).toFixed(2))
              return discounted
            } else {
              return total
            }
          }, 0)
        }
        let min = discount
        return { "min": min, "remaining": Number(min) - Number(remaining) }
      } else if (promo.itemGained == 'discount' && !!promo.gainedItems && promo.gainedItems.length > 0) {
        let billServicesWithDiscount = [...this._bill.billServices]
        let totalDiscount = 0;
        const promoDiscount = Number((Number(promo.discount) / 100).toFixed(2))

        let promoCustomerItems = this.promosCustomerUsed.promosCustomerItems.filter(el => el.usageProgress != 'target');
        for (let i = 0; i < promo.gainedItems.length; i++) {
          
          if ((promo.gainedItems[i].quantity === undefined || promo.gainedItems[i].quantity === null) 
            && promo.gainType == 'price') {
            promo.gainedItems[i].quantity = Infinity
          }

          for (let k = 0; k < billServicesWithDiscount.length; k++) {
            if (!!billServicesWithDiscount[k] && promo.gainedItems[i].serviceId == billServicesWithDiscount[k].serviceId) {
              let servicePrice = Number(this.calculateEditedPrice(billServicesWithDiscount[k]))
              if (!!billServicesWithDiscount[k].discountType && !!billServicesWithDiscount[k].discountValue) {
                servicePrice = this.calculateDiscountShot(billServicesWithDiscount[k], servicePrice)
              }

              if (promoCustomerItems.length == 0) {
                if (promo.gainedItems[i].quantity >= billServicesWithDiscount[k].quantity) {
                  const totalPrice = Number((Number(billServicesWithDiscount[k].quantity) * servicePrice)).toFixed(2)
                  totalDiscount = Number(Number(totalDiscount) + Number(Number(totalPrice).toFixed(2) * Number(promoDiscount))).toFixed(2)
                } else if (promo.gainedItems[i].quantity < billServicesWithDiscount[k].quantity) {
                  let totalPrice = Number((Number(promo.gainedItems[i].quantity) * servicePrice)).toFixed(2)
                  totalDiscount = Number(Number(totalDiscount) + Number(Number(totalPrice).toFixed(2) * Number(promoDiscount))).toFixed(2)
                }
              } else {
                let promoCustomerItem = promoCustomerItems.find((pci) => pci.promosItem.serviceId == billServicesWithDiscount[k].serviceId)
                let remainingQuantity = Infinity
                
                if (!!promoCustomerItem) {
                  remainingQuantity = Number(promo.gainedItems[i].quantity) - Number(promoCustomerItem.usedQuantity)
                }

                let quantityToDiscount = Math.min(remainingQuantity, Number(billServicesWithDiscount[k].quantity))
                let totalPrice = Number((Number(quantityToDiscount) * servicePrice)).toFixed(2)

                totalDiscount = Number(Number(totalDiscount) + Number(Number(totalPrice).toFixed(2) * Number(promoDiscount))).toFixed(2)
              }
            }
          }
        }

        let serviceIdsIncludedInPromo = promo.gainedItems.map(gi => gi.serviceId)
        let billServiceNotIncludedInPromo = billServicesWithDiscount.filter((bs) => !serviceIdsIncludedInPromo.includes(bs.serviceId))
        /*for (let i = 0; i < billServiceNotIncludedInPromo.length; i += 1) {
          if(!!Number(billServiceNotIncludedInPromo[i].discount))
            totalDiscount = Number((Number(totalDiscount) + Number(billServiceNotIncludedInPromo[i].discount)).toFixed(2))
        }

        let totalItemsDiscount = this.discountedBillItems.reduce((accumulator, current) => {
          return accumulator + Number(current.discount)
        }, 0)
        totalDiscount = Number((Number(totalDiscount) + Number(totalItemsDiscount)).toFixed(2))
        */
        return totalDiscount
      } else if (promo.itemGained == 'services') {
        //GAINED SERVICES
        let totalServiceDiscount = this.discountedBillServices.reduce((accumulator, current) => {
          return accumulator + Number(current.discount)
        }, 0)

        let totalItemsDiscount = this.discountedBillItems.reduce((accumulator, current) => {
          return accumulator + Number(current.discount)
        }, 0)

        return Number((Number(totalServiceDiscount) + Number(totalItemsDiscount)).toFixed(2))
      }
    } else {
      return 0
    }
  }

  _getCardMaxDiscountTotal() {
    let areServicesPresent = false
    let areItemsPresent = false

    if (!!this._bill) {
      areServicesPresent = !!this._bill.billServices && this._bill.billServices.length > 0 && !!this._customerCard && !!this._customerCard.serviceDiscounts
      areItemsPresent = !!this._bill.billItems && this._bill.billItems.length > 0 && !!this._customerCard && !!this._customerCard.productsDiscounts
    }

    if (!!this._customerCard && !areItemsPresent && !!areServicesPresent && this._useCustomerCard && this._customerCard.remaining !== 0) {
      return (Number(this._customerCard.remaining) / (1 - this._customerCard.serviceDiscounts / 100)).toFixed(2)
    } else if (!!this._customerCard && !areServicesPresent && !!areItemsPresent && this._useCustomerCard && this._customerCard.remaining !== 0) {
      return (Number(this._customerCard.remaining) / (1 - this._customerCard.productsDiscounts / 100)).toFixed(2)
    } else if (!!this._customerCard) {
      //TODO: da implementare quando ci sono entrambi gli sconti
      if (this._customerCard.serviceDiscounts == this._customerCard.productsDiscounts)
        return (Number(this._customerCard.remaining) / (1 - this._customerCard.serviceDiscounts / 100)).toFixed(2)
      else
        return (Number(this._customerCard.remaining) / (1 - Math.max(this._customerCard.serviceDiscounts, this._customerCard.productsDiscounts) / 100)).toFixed(2)
    } else {
      return 0
    }
  }

  calculateDiscountOnTotal() {
    if (this._promosCustomers.length > 0 && this._usePromo) {
      const promosCustomer = this.promosCustomerUsed
      const promo = !!promosCustomer ? promosCustomer.promo : undefined

      if (!promosCustomer || !promo) {
        return 0
      } else if (promo.itemGained == 'amount') {
        const remainingAmount = Number(promosCustomer.remaining).toFixed(2)
        return Math.min(this.total, remainingAmount)
      }
    }
  }

  calculateSubTotal() {
    if (!!this.discount) {
      return Number((this.total - Number(this.discount)).toFixed(2))
    } else {
      return this.total
    }
  }

  calculateToBePaid() {
    if (!!this.subTotal && this._useCustomerCard) {
      let toBePaid = Number((this.subTotal - Number(this.cardRemaining)).toFixed(2))
      return toBePaid < 0 ? 0 : toBePaid
    } else {
      return this.subTotal < 0 ? 0 : this.subTotal
    }
  }

  calculateNewCardRemaining() {
    const cardRemaining = this.cardRemaining

    if (!!cardRemaining) {
      if (this._useCustomerCard) {
        let newCustomerCardRemaining = (cardRemaining - this.subTotal).toFixed(2)
        return newCustomerCardRemaining > 0 ? newCustomerCardRemaining : 0
      } else {
        return cardRemaining
      }
    } else {
      return undefined
    }
  }

  _emitUpdated() {
    this._executeAsyncCallbacksOf('update')
  }
}

export default new BillCalculator()